.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #312783;
  font-family: Arial, Helvetica, sans-serif;
}

